
import { defineComponent } from 'vue';
import * as Stomp from '@stomp/stompjs';
import timeService from "@/services/timeService";
import DatePicker from 'vue3-datepicker';
import SmallSpinner from '@/components/SmallSpinner.vue';
import { Category, Status, Type, Direction, TecdocDirection, DirectionKey, TecdocDirectionKey, CombineDirectionKey } from '@/components/types/enums';
import axios from "axios";
import SearchDropdown from '@/components/search/SearchDropdown.vue';
const SockJS = require('sockjs-client');
import ToggleButton from '@/components/admin-panel/ToggleButton.vue'
import cross from '@/services/cross';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import { store } from '@/store';
import BaseError from '@/components/ui/BaseError.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import { EnvKey } from '@/models/GlobalModel';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import globalMixin from '@/mixins/globalMixin';
import { SocketSubscribeOutputBody } from '@/plugins/SocketManager';
import { IMessage, StompHeaders } from '@stomp/stompjs';

export interface SocketSubscribeOutput {
  headers: Headers
  body: string
  statusCode: string
  statusCodeValue: number
}

export interface Headers {
  "Content-Disposition": string[]
  "Content-Type": string[]
}

export default defineComponent ({
  name: 'ArticleExportWrapper',
  components: { BaseSelect, BaseIcon, DatePicker, SmallSpinner, SearchDropdown, ToggleButton, BaseError },
  mixins: [globalMixin],
  data: () => ({
    isApplicLoading: false as boolean,
    stompClient: null as any,
    res: null as any,
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
      monthBeforeYear: false,
    },
    isLoading: '' as string,
    newArticleExport: {
      showStatus: false,
      productGroups: [] as any,
      productSubGroups: [] as any,
      categories: [] as any,
      saleTypes: [] as any,
      statuses: [] as any,
    } as any,
    selectedBrandTo: '' as string,
    selectedBrandFrom: '' as string,
    categories: Category,
    statuses: Status,
    types: Type,
    percent: 0 as number,
    blobDataErrors: null as any
  }),
  props: {},
  computed: {
    directionKey() {
      return {
        ...DirectionKey,
        ...TecdocDirectionKey,
      }
    },
    directions(): (typeof Direction) | (typeof TecdocDirection) {
      return {
        ...Direction,
        ...(!this.isDemo && { ...TecdocDirection }),
      }
    },
    getDirectionName() {
      return (item: (keyof typeof Direction) & (keyof typeof TecdocDirection)) => {
        return this.directions[item]
      }
    },
    getLastElementOfProductGroups() {
      return (fieldName: 'productGroups' | 'productSubGroups' = 'productGroups') => {
        const productGroups = this.newArticleExport[fieldName]
        return productGroups?.length ? productGroups[productGroups.length - 1] : null
      }
    },
    getLabelByLastElementOfBrands() {
      return (type: 'TO' | 'FROM') => {
        const brandDirection = type === 'TO' ? 'brandTo' : 'brandFrom'
        const brands = this.newArticleExport?.[brandDirection]

        return brands?.length
          ? brands[brands.length - 1].brand
          : ''
      }
    },
    getRandomArbitrary() {
      return (min: number, max: number) => {
        return Math.random() * (max - min) + min;
      }
    }
  },
  methods: {
    downloadReportFileErrors() {
      let date = timeService.createDateForViewFromUTCTime();
      const blob = new Blob([this.blobDataErrors], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `Price_report_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    deleteBrand(type: 'TO' | 'FROM', brandIndex: number) {
      const brandDirection = type === 'TO' ? 'brandTo' : 'brandFrom'
      this.newArticleExport?.[brandDirection]?.splice(brandIndex, 1)
    },
    async exportTecDocApplicab() {
      const {
        showStatus,
        productGroups,
        productSubGroups,
        categories,
        saleTypes,
        statuses,
        direction,
        brandFrom,
        brandTo
      } = this.newArticleExport

      const preparedInfo = {
        articles: [],
        articleIds: [],
        showStatus,
        statuses,
        categories,
        saleTypes,
        commentArticles: [],
        commentApplics: [],
        articleReplaces: [],
        complex: [],
        productGroups: [...productGroups.map((productGroup: any) => productGroup.id)],
        productSubGroups: [...productSubGroups.map((productSubGroup: any) => productSubGroup.id)],
        ...(brandTo?.length && { brandTo: [...brandTo.map((brand: any) => brand.brandId)] }),
        ...(brandFrom?.length && { brandFrom: [...brandFrom.map((brand: any) => brand.brandId)] })
      }

      this.isApplicLoading = true

      if (localStorage.getItem('loggedUser') !== null) {
        this.$socketService.send(`/api/tecdoc/${localStorage.getItem('cid')}/tecdocFileApplicab`, JSON.stringify(preparedInfo), {})
      }

      this.isApplicLoading = false
    },
    async exportTecDocCrosses() {
      const {
        showStatus,
        productGroups,
        productSubGroups,
        categories,
        saleTypes,
        statuses,
        direction,
        brandFrom,
        brandTo
      } = this.newArticleExport

      const payload = {
        articles: [],
        articleIds: [],
        showStatus,
        statuses,
        categories,
        saleTypes,
        commentArticles: [],
        commentApplics: [],
        articleReplaces: [],
        complex: [],
        productGroups: [...productGroups.map((productGroup: any) => productGroup.id)],
        productSubGroups: [...productSubGroups.map((productSubGroup: any) => productSubGroup.id)],
        ...(brandTo?.length && { brandTo: [...brandTo.map((brand: any) => brand.brandId)] }),
        ...(brandFrom?.length &&{ brandFrom: [...brandFrom.map((brand: any) => brand.brandId)] })
      }

      this.isApplicLoading = true

      try {
        const response = await cross.generateFileToTecDoc(payload)
        const blob = new Blob(["\ufeff", response.data], {type:"text/plain;charset=UTF-8"})
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `Crosses.xls`);
        link.setAttribute('charset', 'UTF-8')
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.isApplicLoading = false
      } catch (e) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    getArticles() {
      this.isLoading = 'true';
      let from = '';
      let fromEl = document.getElementById('from')! as any;
      if (fromEl) from = fromEl.value;
      let text = '';
      let textEl = document.getElementById('text')! as any;
      if (textEl) text = textEl.value;

      if (localStorage.getItem('loggedUser') != null) {
        const {
          productGroups,
          productSubGroups,
        } = this.newArticleExport

        const filteredData = {
          ...this.newArticleExport,
          productGroups: [...productGroups.map((productGroup: any) => productGroup.id)],
          productSubGroups: [...productSubGroups.map((productSubGroup: any) => productSubGroup.id)],
          ...(this.newArticleExport?.brandTo?.length && { brandTo: [...this.newArticleExport.brandTo.map((brand: any) => brand.brandId)] }),
          ...(this.newArticleExport?.brandFrom?.length && { brandFrom: [...this.newArticleExport.brandFrom.map((brand: any) => brand.brandId)] })
        }

        this.$socketService.send(`/article/${localStorage.getItem('cid')}/cross/tree`, JSON.stringify(filteredData), {})
      }
    },
    addGroup(chosenProductGroup: any, fieldName: 'productGroups' | 'productSubGroups' = 'productGroups') {
      if (chosenProductGroup.id) {
        const isProductGroupExist = this.newArticleExport[fieldName].findIndex((pg: any) => pg.id === chosenProductGroup.id) >= 0

        if (!isProductGroupExist) {
          this.newArticleExport[fieldName].push(chosenProductGroup)
        }
      }
    },
    setBrand(value: any, type: string) {
      if (value) {
        if (type === 'FROM') {
          this.newArticleExport.brandFrom = [
            ...(this.newArticleExport.brandFrom ? [...this.newArticleExport.brandFrom] : []),
            value
          ]
          this.selectedBrandFrom = value.brand
        }

        if (type === 'TO') {
          this.newArticleExport.brandTo = [
            ...(this.newArticleExport.brandTo ? [...this.newArticleExport.brandTo] : []),
            value
          ]
          this.selectedBrandTo = value.brand
        }

      }
    },
    setDirectionParameter(value: any) {
      if (this.newArticleExport.direction && this.newArticleExport.direction === value) {
        delete this.newArticleExport.direction
        delete this.newArticleExport.brandFrom
        delete this.newArticleExport.brandTo
      } else {
        this.newArticleExport.direction = value
      }
    },
    setParameter(criteria: string, status: string) {
      if (status.length || String(status.length)) {
        if (this.newArticleExport[criteria] && this.newArticleExport[criteria].length) {
          if (!this.newArticleExport[criteria].includes(status)) {
            this.newArticleExport[criteria].push(status)
          } else {
            this.newArticleExport[criteria].splice(this.newArticleExport[criteria].indexOf(status), 1)
          }
        } else {
          this.newArticleExport[criteria] = [status]
        }
      } else {
        this.newArticleExport[criteria] = []
      }
    },

    showMessageOutput(messageOutput: any, fileName: string) {
      this.res = JSON.parse(messageOutput.body)
      if (this.res.headers &&
        this.res.headers['Content-Disposition'] &&
        this.res.headers['Content-Disposition'].length &&
        this.res.headers['Content-Disposition'].some((el: any) => el.includes('attachment;'))) {
        this.isLoading = '';
        this.percent = 0;

        let table = this.res.body;
        let byteTable = atob(this.res.body);
        let n = byteTable.length;
        let u8arr = new Uint8Array(n);
        while(n--){
          u8arr[n] = byteTable.charCodeAt(n);
        }

        let today = new Date();
        let date = timeService.createDateForViewFromUTCTime() + '-' + today.getHours() + '-' + today.getMinutes();
        const blob = new Blob([u8arr], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${fileName}_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (messageOutput && messageOutput.command && messageOutput.command === 'MESSAGE') {
        if (messageOutput.body && typeof messageOutput.body === "string" && !messageOutput.body.includes('{')) {
          this.percent = JSON.parse(messageOutput.body)
        }
      }
    },
  },
  async mounted() {
    this.$socketService.connect(() => {
      this.$socketService.subscribe('/user/queue/export/tecdoc', (messageOutput: IMessage) => {
        this.showMessageOutput(messageOutput, 'Tecdoc_export')
      })

      this.$socketService.subscribe('/user/queue/export/crosses', (messageOutput: IMessage) => {
        this.showMessageOutput(messageOutput, 'Cross_export');
      });
    })
  },
})
